import { $Props } from '@ravnur/core/typings/tsx';
import $t from '@ravnur/l10n/$t';
import { Vue } from 'vue-class-component';

import AuthMessage from '../../components/auth-message/auth-message';
import AuthProvidersList from '../../components/auth-providers-list/auth-providers-list';
import AuthTabs from '../../components/auth-tabs/auth-tabs';
import BaseLoginPage from '../../components/base-login-page/base-login-page';
import ForgotPasswordForm from '../../components/forgot-password-form/forgot-password-form';
import StackComponent from '../../components/stack-component/stack-component';
import StateFetcher from '../../components/state-fetcher/state-fetcher';

import { cleanLocation } from '../../helpers/location-cleaner';
import { messageFromError } from '../../transformers/message-from-error';

import { ExternalAuthProvider } from '../../types/AuthProvider';
import { AuthService } from '../../types/AuthService';
import { LabeledComponentDescription } from '../../types/LabeledComponentDescription';
import { Message } from '../../types/Message';
import { AuthSettings } from '../../types/AuthSettings';

import './login-page.scss';
import { AxiosResponse } from 'axios';

class Props<T> {
  service!: AuthService<T>;
}

type Emits<T> = {
  onAuth: (data: T) => void;
  onError: (e: Record<string, any>) => void;
};

type LocationData = {
  errorCode?: string | null;
  successCode?: string | null;
  operationId?: string | null;
};

const CN = 'login-page';
const BASE_STACK = ['login', 'forgot'];
const TABS: LabeledComponentDescription[] = [{ key: 'forgot', label: 'forgotTab' }];

export default class LoginPage<T> extends Vue.with(Props) {
  declare $props: $Props<Props<T>, Emits<T>>;
  declare service: AuthService<T>;

  private logo: Nullable<string> = null;
  private providers: ExternalAuthProvider[] = [];
  private isRegistrationAvailable = false;
  private isWebAuthAvailable = false;
  private message: Nullable<Message> = null;
  private locationData: Nullable<LocationData> = null;
  private authSettings: Nullable<AuthSettings> = null;
  private loading = false;

  protected get isAutoAuthenticationNeeded() {
    const { isWebAuthAvailable, providers, message } = this;
    return !message && !isWebAuthAvailable && providers.length === 1;
  }

  private get operationId() {
    return this.locationData?.operationId ?? null;
  }

  private get stackSlots() {
    return {
      login: this.renderProviders,
      forgot: this.renderTabs,
    };
  }

  private get tabsSlots() {
    return {
      forgot: this.renderForgotForm,
    };
  }

  created() {
    this.locationData = cleanLocation('errorCode', 'successCode', 'operationId');
    this.waitOperationIfNeeded();
  }

  render() {
    const { operationId, isAutoAuthenticationNeeded } = this;
    if (operationId) {
      return <spinner />;
    }

    if (isAutoAuthenticationNeeded) {
      return this.renderProviders();
    }

    return (
      <StateFetcher
        fetch={this.service.repository.check}
        onError={this.handleError}
        onSuccess={this.handleSuccessfulAuthentication}
      >
        <BaseLoginPage class={CN} logo={this.logo} title="title">
          <StackComponent
            keys={BASE_STACK}
            v-slots={this.stackSlots}
            onChangedIndex={this.hideMessage}
          />
          <AuthMessage class={`${CN}__message`} message={this.message} />
        </BaseLoginPage>
      </StateFetcher>
    );
  }

  private renderTabs() {
    return <AuthTabs class={CN} forceShow={true} tabs={TABS} v-slots={this.tabsSlots} />;
  }

  private renderForgotForm() {
    return (
      <ForgotPasswordForm repository={this.service.repository} onMessage={this.handleMessage} />
    );
  }

  private renderProviders() {
    if (this.loading) {
      return <spinner />;
    }

    return (
      <AuthProvidersList
        isAutoLoginAvailable={!this.message}
        isRegistrationAvailable={this.isRegistrationAvailable}
        isWebAuthAvailable={this.isWebAuthAvailable}
        providers={this.providers}
        service={this.service}
        onAuth={this.handleSuccessfulAuthentication}
        onMessage={this.handleMessage}
      />
    );
  }

  private async waitOperationIfNeeded() {
    const { operationId, service } = this;
    if (!operationId) {
      return;
    }
    try {
      await service.operationWaiter(operationId);
    } catch (e: any) {
      this.message = messageFromError(e);
    } finally {
      if (this.locationData) {
        this.locationData.operationId = null;
      }
    }
  }

  private async loadAuthSettings() {
    try {
      this.loading = true;
      this.authSettings = await this.service.repository.getAuthSettings();
    } catch (e) {
      this.$emit('error', e);
    } finally {
      this.loading = false;
    }
  }

  private async handleError(e: AxiosResponse['data'] & { status: AxiosResponse['status'] }) {
    if (e.status !== 401) {
      return;
    }

    await this.loadAuthSettings();

    if (!this.authSettings) {
      return;
    }

    this.logo = this.authSettings.logoPath;
    this.providers = this.authSettings.availableProviders;
    this.isRegistrationAvailable = this.authSettings.isRegistrationAvailable;
    this.isWebAuthAvailable = this.authSettings.isWebAuthAvailable;

    this.$nextTick(() => {
      const errorCode = this.locationData?.errorCode;
      const successCode = this.locationData?.successCode;
      if (errorCode) {
        this.message = { text: $t('login', errorCode), type: 'error' };
      }
      if (successCode) {
        this.message = { text: $t('login', successCode), type: 'success' };
      }
      this.locationData = null;
    });
  }

  private handleSuccessfulAuthentication(data: T) {
    this.$emit('auth', data);
  }

  private handleMessage(message: Message) {
    this.message = message;
  }

  private hideMessage() {
    this.message = null;
  }
}
